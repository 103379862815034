import "./App.css";
import { Routes, Route /*Link*/ } from "react-router-dom";

import NewsList from "./components/NewsList";

function App() {
  return (
    <div className="bg-slate-200">
      <Routes>
        <Route path="/" element={<NewsList />} />
        {/* <Route path="/news" element={<NewssList />} />
        <Route path="/add" element={<AddNews />} />
        <Route path="/news/:id" element={<News />} /> */}
      </Routes>
      {/* <div class="flex items-center justify-center flex-col min-h-screen bg-slate-200">
        <h1 className="top-0 text-8xl text-center text-black pb-5">
          だいきえの新聞
        </h1>

        <div class="break-inside relative overflow-hidden flex flex-col justify-between space-y-2 text-sm rounded-xl max-w-[60rem] p-4 mb-4 bg-[#5E17F4] text-white">
          <div class="flex flex-row items-center space-x-3">
            <a href="https://computerhoy.com/noticias/tecnologia/hace-133-anos-nacio-nintendo-japon-como-empresa-naipes-1129319">
              <span class="font-black text-4xl">
                Hace 133 años nació Nintendo en Japón como empresa de naipes -
                Computer Hoy
              </span>

              <div class="flex justify-between items-center pt-4">
                <span>Thu Sep 23 2022 19:29</span>
                <span>Computer Hoy</span>
              </div>
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default App;
