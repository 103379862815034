import React, { useState, useEffect, useRef } from "react";
import NewsDataService from "../services/News";
// import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";

const NewsList = () => {
  const dayjs = require("dayjs");
  require("dayjs/locale/es");
  dayjs.locale("es");
  const [news, setNews] = useState([]);
  // const [currentNews, setCurrentNews] = useState(null);
  // const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchTitle /*setSearchTitle*/] = useState("");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(4);

  const pageSizes = [5, 10, 20, 25];

  const ref = useRef(null);

  // const onChangeSearchTitle = (e) => {
  //   const searchTitle = e.target.value;
  //   setSearchTitle(searchTitle);
  // };

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveNews = () => {
    const params = getRequestParams(searchTitle, page, pageSize);

    NewsDataService.getAll(params)
      .then((response) => {
        const { news, totalPages } = response.data;

        setNews(news);
        setCount(totalPages);

        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveNews, [searchTitle, page, pageSize]);

  const handlePageChange = (event, value) => {
    setPage(value);
    ref.current.scrollIntoView();
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // const refreshList = () => {
  //   retrieveNews();
  //   setCurrentNews(null);
  //   setCurrentIndex(-1);
  // };

  // const setActiveNews = (news, index) => {
  //   setCurrentNews(news);
  //   setCurrentIndex(index);
  // };

  // const removeAllNews = () => {
  //   NewsDataService.removeAll()
  //     .then((response) => {
  //       console.log(response.data);
  //       refreshList();
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // const findByTitle = () => {
  //   NewsDataService.findByTitle(searchTitle)
  //     .then((response) => {
  //       setNews(response.data);
  //       console.log(response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  return (
    <div className="flex items-center justify-center flex-col min-h-screen pt-5 bg-slate-200">
      <h1
        className="top-0 lg:text-8xl text-6xl font-bold text-center text-black pb-5"
        ref={ref}
      >
        だいきえの新聞
      </h1>

      {news &&
        news.map((news, index) => (
          <div
            className="break-inside relative overflow-hidden flex flex-col justify-between space-y-2 mx-4 text-sm rounded-xl p-4 mb-4 lg:min-w-[60rem] lg:max-w-[60rem] text-black border-solid border-2 border-orange-200 bg-amber-50"
            key={index}
            id={index}
          >
            <div className="flex flex-row items-center space-x-3">
              <a href={news.url} alt={news.title}>
                <span className="font-black text-4xl">{news.title}</span>
              </a>
            </div>
            <div className="flex flex-row space-x-3 justify-start	items-start">
              <img
                src={news.image}
                alt={news.source_name}
                className="h-20 rounded"
              />
              <p className="text-lg">{news.content.substring(0, 200)}</p>
            </div>
            <div className="flex justify-between items-center pt-4">
              <span>
                {dayjs(news.publishedAt).format("DD MMMM YYYY HH:mm")}
              </span>
              <span>
                <a href={news.source_url} alt={news.source_name}>
                  {news.source_name}
                </a>
              </span>
            </div>
          </div>
        ))}

      <div className="flex mb-5">
        <Pagination
          className=""
          count={count}
          color="secondary"
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <select
          onChange={handlePageSizeChange}
          value={pageSize}
          className="border border-[#5E17F4] w-13"
        >
          {pageSizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default NewsList;
